
import { Component, Prop, Vue } from "nuxt-property-decorator";
import Icon from "~/components/atoms/embedding/Icon.vue";
import { returnPriceFormatDE } from "~/mixins/formatting";

@Component({
  components: {
    Icon,
  },
  methods: {
    returnPriceFormatDE,
  },
})
export default class PriceInfoModal extends Vue {
  @Prop({ required: true }) isVisible!: boolean;
  @Prop({ required: true }) dealerData!: any;
  @Prop({ required: true }) selectPelletsOption!: string;
  @Prop({ required: true }) amount!: string | number;
  @Prop() weightPerPallet?: number;
  @Prop() totalWeight?: number;

  get isSinglePallet(): boolean {
    return parseInt(String(this.amount)) === 1000;
  }

  get vatMonetary(): number {
    if (this.selectPelletsOption === "pallet") {
      return this.dealerData.pricing.ton.vatMonetaryScoped;
    }
    return this.dealerData.pricing.ton.vatMonetary;
  }

  get totalVatMonetary(): number {
    if (this.selectPelletsOption === "pallet") {
      return this.dealerData.pricing.total.vatMonetaryScoped;
    }
    return this.dealerData.pricing.total.vatMonetary;
  }

  get requestedPallets(): number {
    return parseInt(String(this.amount)) / 1000;
  }

  get singlePalletWeight() {
    if (this.selectPelletsOption === "bigbag") {
      return "1000";
    }
    return this.weightPerPallet;
  }

  get totalPalletWeight() {
    if (this.selectPelletsOption === "bigbag") {
      return this.requestedPallets * 1000;
    }
    return this.totalWeight;
  }

  get pricePerPalletNetto(): number {
    if (this.selectPelletsOption === "pallet") {
      return this.dealerData.pricing.ton.nettoScoped;
    }
    return this.dealerData.pricing.ton.netto;
  }

  get totalPriceNetto(): number {
    return this.pricePerPalletNetto * this.requestedPallets;
  }

  get pricePerPalletBrutto(): number {
    if (this.selectPelletsOption === "pallet") {
      return this.dealerData.pricing.ton.bruttoScoped;
    }
    return this.dealerData.pricing.ton.brutto;
  }

  get totalPriceBrutto(): number {
    if (this.selectPelletsOption === "pallet") {
      return this.dealerData.pricing.total.bruttoScoped;
    }
    return this.dealerData.pricing.total.brutto;
  }

  get deliveryIncluded(): string {
    return this.dealerData.pricing.common.isDeliveryChargeIncluded
      ? "Inklusive"
      : "Nicht inklusive";
  }

  get pelletsOptionIcon(): string {
    if (this.$route.query.type === "bigbag") {
      return "icon-amount-pellets-bigbag.svg";
    }
    return "icon-amount-pellets-palet.svg";
  }

  formatPrice(price: number): string {
    return price.toFixed(2).replace(".", ",");
  }

  closeModal() {
    this.$emit("close");
  }
}
